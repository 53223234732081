export default function MessageActions({
  setMessage,
  setViewEdit,
  message,
  viewEdit,
}) {
  return (
    <div className="action-menu">
      {message !== null && viewEdit === "view" && (
        <div onClick={() => setMessage(null)} className="button-primary">
          {message === "new" ? "Cancel" : "Back"}
        </div>
      )}
      {message === null && (
        <div onClick={() => setMessage("new")} className="button-primary">
          New
        </div>
      )}
    </div>
  );
}
