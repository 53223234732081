import { useState } from "react";
import { saveMessage } from "../services/dataService";

export default function MessageDetail({ message }) {
  const [start, setStart] = useState(message.start);
  const [end, setEnd] = useState(message.end);
  const [active, setActive] = useState(message.active);
  const [content, setContent] = useState(message.content);

  function save() {
    if (start !== "" && end !== "" && content !== "" && active !== "") {
      saveMessage(message.id, start, end, active, content);
    }
  }

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>Message Details</h4>
      </div>
      <div className="panel-body">
        <table>
          <tbody>
            <tr>
              <th>
                <div className="text">Start Date:</div>
              </th>
              <td>
                <input
                  type="date"
                  defaultValue={start}
                  onChange={(e) => setStart(e.target.value)}
                />
              </td>
              <th>
                <div className="text">End Date:</div>
              </th>
              <td>
                <input
                  type="date"
                  defaultValue={end}
                  onChange={(e) => setEnd(e.target.value)}
                />
              </td>
              <th>
                <div className="text">Active:</div>
              </th>
              <td>
                <input
                  type="checkbox"
                  checked={active}
                  onChange={() => setActive(!active)}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <th>
              <div className="text">Content:</div>
            </th>
            <tr>
              <td>
                <input
                  className="text-long"
                  type="text"
                  rows="5"
                  max="100"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </td>
            </tr>
            <td>
              <button onClick={() => save()} className="button-primary">
                Save
              </button>
            </td>
          </tbody>
        </table>
      </div>
    </div>
  );
}
