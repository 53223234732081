import { useState, useEffect } from "react";
import { createLLC, getLLCs } from "../../services/LLCService";

export default function LLCList({ profile, setTab }) {
  const [LLCs, setLLCs] = useState(null);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [systemId, setSystemId] = useState("");
  const [appId, setAppId] = useState("");
  const [newRow, setNewRow] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getLLCs(profile.orgCode).then(setLLCs);
  }, [profile]);

  function save() {
    if (name !== "") {
      if (status !== "") {
        if (systemId !== "") {
          if (appId !== "") {
            createLLC(profile.orgCode, name, status, systemId, appId);
            getLLCs(profile.orgCode).then(setLLCs);
            setNewRow(false);
            setName("");
            setStatus("");
            setSystemId("");
            setAppId("");
          } else {
            setMessage("Application ID is required.");
          }
        } else {
          setMessage("System ID is required.");
        }
      } else {
        setMessage("Status is required.");
      }
    } else {
      setMessage("Name is required.");
    }
  }

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>Communities</h4>
        {message !== "" && <div className="text-error">{message}</div>}
        {!newRow && (
          <button className="button-primary" onClick={() => setNewRow(true)}>
            New
          </button>
        )}
        {newRow && (
          <button className="button-primary" onClick={() => save()}>
            Save
          </button>
        )}
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Name</div>
              </th>
              <th>
                <div className="text">Status</div>
              </th>
              <th>
                <div className="text">System ID</div>
              </th>
              <th>
                <div className="text">Application ID</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {LLCs ? (
              LLCs.map((l) => (
                <tr key={l.id}>
                  <td>
                    <div className="text">
                      <button className="button-link" onClick={() => setTab(l)}>
                        {l.name}
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="text">{l.status}</div>
                  </td>
                  <td>
                    <div className="text">{l.systemId}</div>
                  </td>
                  <td>
                    <div className="text">{l.appId}</div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <div className="text">No LLCs Found</div>
                </td>
              </tr>
            )}
            {newRow && (
              <tr>
                <td>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </td>
                <td>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Hidden">Hidden</option>
                  </select>
                </td>
                <td>
                  <input
                    value={systemId}
                    onChange={(e) => setSystemId(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    value={appId}
                    onChange={(e) => setAppId(e.target.value)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
