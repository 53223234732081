import { useEffect, useState } from "react";
import { auth } from "../firebaseConfig";
import { createNewUser, signIn, resetPassword } from "../services/authService";
import { verifyOrgCode } from "../services/dataService";
import partnerportallogo from "../assets/partnerportallogo.png";

function ExistingUser({ setAction, setMessage, setProfile }) {
  const [emailContent, setEmail] = useState("");
  const [passwordContent, setPassword] = useState("");

  function forgotPassword() {
    if (emailContent !== "") {
      resetPassword(emailContent, setMessage);
    } else {
      setMessage("Please enter your email address to reset your password.");
    }
  }

  function submit(e) {
    e.preventDefault();
  }

  return (
    <div className="wrapper">
      <form onSubmit={submit}>
        <input
          placeholder="Email"
          value={emailContent}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="username"
        />
        <input
          type="password"
          placeholder="Password"
          value={passwordContent}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />

        <button
          className="button-primary"
          onClick={() =>
            signIn(emailContent, passwordContent, setMessage, setProfile)
          }
        >
          Login
        </button>
        <button
          className="button-primary"
          onClick={() => setAction("createUser")}
        >
          Create Account
        </button>
        <div onClick={() => forgotPassword()} className="button-link">
          Forgot Password?
        </div>
      </form>
    </div>
  );
}

function CreateUser({ setAction, setMessage }) {
  const [emailContent, setEmail] = useState("");
  const [passwordContent, setPassword] = useState("");
  const [confirmPassword, setConfirm] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [verified, setVerified] = useState();
  const [nameContent, setName] = useState("");

  function verifyUser() {
    if (passwordContent === confirmPassword && passwordContent !== "") {
      if (nameContent !== "") {
        verifyOrgCode(orgCode, emailContent, setVerified);
      } else {
        setMessage("Name cannot be blank.");
      }
    } else {
      setMessage("Passwords do not match and cannot be blank.");
    }
  }

  useEffect(() => {
    if (verified === true) {
      createNewUser(
        auth,
        emailContent,
        passwordContent,
        orgCode,
        setMessage,
        nameContent
      );
    } else if (verified === false) {
      setMessage("Org Code Invalid or Email Not Valid for Org Code.");
    }
    // eslint-disable-next-line
  }, [verified]);

  function submit(e) {
    e.preventDefault();
  }

  return (
    <div className="wrapper">
      <form onSubmit={submit}>
        <input
          value={nameContent}
          onChange={(e) => setName(e.target.value)}
          placeholder="First & Last Name"
        />
        <input
          type="email"
          value={emailContent}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          autoComplete="username"
        />
        <input
          value={passwordContent}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
          autoComplete="new-password"
        />
        <input
          value={confirmPassword}
          onChange={(e) => setConfirm(e.target.value)}
          type="password"
          placeholder="Confirm Password"
          autoComplete="confirm-new-password"
        />
        <input
          value={orgCode}
          onChange={(e) => setOrgCode(e.target.value)}
          placeholder="Org Code"
        />
        <button className="button-primary" onClick={() => verifyUser()}>
          Create User
        </button>
        <button className="button-primary" onClick={() => setAction("login")}>
          Back to Login
        </button>
      </form>
    </div>
  );
}

export default function Login({ setProfile }) {
  const [action, setAction] = useState("login");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");
  }, [action]);

  return (
    <div className="login-wrapper">
      <div className="login">
        {
          <div>
            <img src={partnerportallogo} alt="logo" height="60" width="60" />
          </div>
        }
        {action === "login" && <h2>Sign In to Partner Portal</h2>}
        {action === "createUser" && <h2>Create An Account</h2>}
        {message !== "" && <h2>{message}</h2>}
        {action === "login" && (
          <ExistingUser
            setProfile={setProfile}
            setAction={setAction}
            setMessage={setMessage}
          />
        )}
        {action === "createUser" && (
          <CreateUser setAction={setAction} setMessage={setMessage} />
        )}
      </div>
    </div>
  );
}
