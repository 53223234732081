import { useState } from "react";

export default function LateStayGroupNew({ adder, terms, setGroup }) {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [term, setTerm] = useState("");
  const [message, setMessage] = useState("");
  const [dropdownId, setDropdownId] = useState("");

  function selectTerm(term) {
    setTerm(term);
    setDropdownId("");
  }

  function submit(e) {
    e.preventDefault();
  }

  function validate(name, date, term) {
    if (name !== "") {
      if (term !== "") {
        if (date !== "") {
          if (term.termDates) {
            if (date >= term.earliest) {
              if (date <= term.latest) {
                adder(name, date, term);
              } else {
                //setMessage(
                //  "Date must be less than or equal to " + term.latest + "!"
                //);
                adder(name, date, term);
              }
            } else {
              setMessage(
                "Date must be greater than or equal to " + term.earliest + "!"
              );
            }
          } else {
            adder(name, date, term);
          }
        } else {
          setMessage("Date cannot be blank!");
        }
      } else {
        setMessage("Term cannot be blank!");
      }
    } else {
      setMessage("Name cannot be blank!");
    }
  }

  return (
    <div className="wrapper">
      <div className="action-menu">
        <button className="button-primary" onClick={() => setGroup(null)}>
          Cancel
        </button>
        <button
          className="button-primary"
          onClick={() => validate(name, date, term)}
        >
          Create Group
        </button>
      </div>
      <form onSubmit={submit} className="panel-primary">
        <h3>{message === "" ? "New Group" : message}</h3>
        <div className="panel-primary-item">
          <div className="label">Name:</div>
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="panel-primary-item">
          <div className="label">Date:</div>
          <input
            value={date}
            type="datetime-local"
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="panel-primary-item">
          <label className="label">Term:</label>
          <div className="wrapper">
            <div
              id="termSelectorDropdown"
              className="dropdown-menu"
              onClick={() => setDropdownId("termSelectorDropdown")}
            >
              <div>{term ? term.name : "Select a Term"}</div>
              <div>&#9660;</div>
            </div>
            {dropdownId === "termSelectorDropdown" && (
              <div className="dropdown-drawer">
                {terms.length ? (
                  terms.map((t) => (
                    <div
                      key={t.id}
                      className="dropdown-drawer-item"
                      onClick={() => selectTerm(t)}
                    >
                      {t.name}
                    </div>
                  ))
                ) : (
                  <div onClick={() => setDropdownId("")}>
                    No Available Terms
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
