import { useState, useEffect } from "react";
import { getTerm } from "../../services/termService";
import { getLLC } from "../../services/LLCService";

export default function LLCApplications({ LLC, term }) {
  const [termObj, setTerm] = useState("");
  const [LLCobj, setLLC] = useState("");

  useEffect(() => {
    if (LLC !== "" && term !== "") {
      getTerm(term).then(setTerm);
      getLLC(LLC).then(setLLC);
    }
  }, [LLC, term]);

  return (
    <div className="module-main">
      <div className="panel-header">
        <h4>
          {term !== "" && LLC !== "" && termObj.name + ": " + LLCobj.name}
        </h4>
        <button className="button-primary">Refresh</button>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Name</div>
              </th>
              <th>
                <div className="text">Status</div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
