import { useState, useEffect } from "react";
import UserDetailsEdit from "./UserDetailsEdit";
import UserDetailsView from "./UserDetailsView";

export default function UserDetails({ user, setUser }) {
  const [viewEdit, setViewEdit] = useState("view");

  useEffect(() => {
    setViewEdit("view");
  }, [user]);

  return (
    <div className="wrapper">
      <div className="module-header">
        <h3>{user.name}</h3>
        {viewEdit === "edit" && (
          <button
            className="button-primary"
            onClick={() => setViewEdit("view")}
          >
            Back
          </button>
        )}
        {viewEdit === "view" && (
          <button
            className="button-primary"
            onClick={() => setViewEdit("edit")}
          >
            Edit
          </button>
        )}
      </div>
      {viewEdit === "view" && <UserDetailsView user={user} setUser={setUser} />}
      {viewEdit === "edit" && (
        <UserDetailsEdit user={user} setViewEdit={setViewEdit} />
      )}
    </div>
  );
}
