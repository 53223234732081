import { useState } from "react";
import LLCList from "./LLCList";
import LLCAssignments from "./LLCAssignments";

export default function LLCConfiguration({ profile }) {
  const [tab, setTab] = useState("list");

  return (
    <div className="module-main">
      <div className="module-header">
        <h3>Living-Learning Communities</h3>
        {tab !== "list" && (
          <button className="button-primary" onClick={() => setTab("list")}>
            Back to List
          </button>
        )}
      </div>
      {tab === "list" && <LLCList profile={profile} setTab={setTab} />}
      {tab !== "list" && <LLCAssignments llc={tab} />}
    </div>
  );
}
