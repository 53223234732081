import { useState, useEffect } from "react";
import {
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { db, auth } from "../firebaseConfig";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export async function resetPassword(email, setMessage) {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      setMessage(
        "If an account exists with your email address, an email has been sent to reset your password."
      );
    })
    .catch((error) => {
      const errorCode = error.code;
      setMessage("ERROR: " + errorCode);
    });
}

export function SignOut() {
  return (
    <button className="button-menu" onClick={() => signOut(auth)}>
      Sign Out
    </button>
  );
}

export function useAuthentication() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
  }, []);
  return user;
}

export function signIn(email, password, setMessage, setProfile) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      //const user = userCredential.user;
      verifyProfile(userCredential);
    })
    .catch((error) => {
      const errorCode = error.code;
      setMessage("ERROR: " + errorCode);
    });
}

async function verifyProfile(userCredential) {
  const emailVerified = userCredential.user.emailVerified;
  if (emailVerified) {
    const snapshot = await getDocs(
      query(
        collection(db, "profiles"),
        where("uid", "==", userCredential.user.uid)
      )
    );
    const userRef = doc(db, "profiles", snapshot.docs[0].id);
    const data = { verified: true };
    await updateDoc(userRef, data);
  }
}

export function createNewUser(
  auth,
  email,
  password,
  orgCode,
  setMessage,
  name
) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      createProfile(user.uid, orgCode, name, email);
      sendEmailVerification(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      //const errorMessage = error.message;
      setMessage("ERROR: " + errorCode);
    });
}

async function createProfile(userId, orgCode, name, email) {
  const data = {
    uid: userId,
    orgCode: orgCode,
    admin: false,
    name: name,
    active: true,
    verified: false,
    email: email,
  };
  const newProfile = await addDoc(collection(db, "profiles"), data);
  return newProfile;
}

export async function getProfile(userId, setProfile) {
  const snapshot = await getDocs(
    query(collection(db, "profiles"), where("uid", "==", userId))
  );
  if (!snapshot.empty) {
    setProfile(snapshot.docs[0].data());
  }
}
