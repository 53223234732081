import { saveKey } from "../services/dataService";

export default function AccessKeyEdit({ selectedKey, setKey }) {
  function updateKey(newStatus) {
    selectedKey.status = newStatus;
    saveKey(selectedKey).then(setKey(null));
  }

  return (
    <div className="popup-wrapper">
      <div className="popup">
        <div className="panel">
          <div className="panel-body">
            <div onClick={() => updateKey("Access")} className="button-link">
              Access
            </div>
            <div
              onClick={() => updateKey("Admin Only")}
              className="button-link"
            >
              Admin Only
            </div>
            <div onClick={() => updateKey("Read Only")} className="button-link">
              Read Only
            </div>
            <div onClick={() => updateKey("No Access")} className="button-link">
              No Access
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
