export default function SideMenu({ toggleMenu }) {
  return (
    <>
      <div className="new-side-menu-background" onClick={() => toggleMenu()} />
      <div className="new-side-menu">
        <div className="new-side-menu-header right">
          <div className="fa fa-times" onClick={() => toggleMenu()} />
        </div>
        <button className="new-side-menu-item" onClick={() => {}}>
          Early Arrival
        </button>
        <button className="new-side-menu-item" onClick={() => {}}>
          Late Stay
        </button>
        <button className="new-side-menu-item" onClick={() => {}}>
          Help
        </button>
        <button className="new-side-menu-item" onClick={() => {}}>
          Admin
        </button>
        <button className="new-side-menu-item" onClick={() => {}}>
          Sign Out
        </button>
      </div>
    </>
  );
}
