import { useState } from "react";
import { globalData } from "../services/dataService";

export default function GroupDetails({
  group,
  viewEdit,
  terms,
  profile,
  message,
  setMessage,
}) {
  const [dropdownId, setDropdownId] = useState("");

  function setTerm(term) {
    group.term = term;
    setDropdownId("");
  }

  function GroupDetailsView() {
    return (
      <div className="panel-body">
        <table>
          <tbody>
            <tr>
              <th>
                <div className="text">Date:</div>
              </th>
              <td>
                <div className="text">{group.date}</div>
              </td>
              <th>
                <div className="text">Term:</div>
              </th>
              <td>
                <div className="text">{group.term.name}</div>
              </td>
              <th>
                <div className="text">Status:</div>
              </th>
              <td>
                <div className="text">{group.status}</div>
              </td>
              <th>
                <div className="text">Owner:</div>
              </th>
              <td>
                <div className="text">
                  {globalData.profiles.get(group.ownerId).name}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  function GroupDetailsEdit() {
    return (
      <div className="panel-body">
        <h3>{message}</h3>
        <div className="panel-primary-item">
          <div className="label">Name:</div>
          <input
            defaultValue={group.name}
            onChange={(e) => (group.name = e.target.value)}
          />
        </div>
        <div className="panel-primary-item">
          <div className="label">Date:</div>
          <input
            defaultValue={group.date}
            type="date"
            onChange={(e) => {
              group.date = e.target.value;
              group.updatedDate = true;
            }}
          />
        </div>
        <div className="panel-primary-item">
          <label className="label">Term:</label>
          <div className="wrapper">
            <div
              id="termSelectorDropdown"
              className="dropdown-menu"
              onClick={() => setDropdownId("termSelectorDropdown")}
            >
              <div>{group.term ? group.term.name : "Select a Term"}</div>
              <div>&#9660;</div>
            </div>
            {dropdownId === "termSelectorDropdown" && (
              <div className="dropdown-drawer">
                {terms.length ? (
                  terms.map((t) => (
                    <div
                      key={t.id}
                      className="dropdown-drawer-item"
                      onClick={() => setTerm(t)}
                    >
                      {t.name}
                    </div>
                  ))
                ) : (
                  <div onClick={() => setDropdownId("")}>
                    No Available Terms
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {profile.admin && (
          <div className="panel-primary-item">
            <div className="label">Status:</div>
            <input
              defaultValue={group.status}
              onChange={(e) => (group.status = e.target.value)}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {viewEdit === "view" && <GroupDetailsView />}
      {viewEdit === "edit" && <GroupDetailsEdit />}
    </>
  );
}
