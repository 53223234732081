import { useState, useEffect } from "react";
//import README from "../../README.txt";
const React = require("react");
const ReactMarkdown = require("react-markdown");

export default function HelpAbout() {
  const [input, setInput] = useState("");

  useEffect(() => {
    //fetch("../../README.md").then((r) => r.text().then((text) => (input = text)));
    fetch("../../README.txt").then((r) => console.log("Line 10: " + r.text()));
    fetch("../../README.txt").then((r) => setInput(r.text()));
  }, []);

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>About</h4>
      </div>
      <div className="panel-body">
        <ReactMarkdown source={input} />
      </div>
    </div>
  );
}
