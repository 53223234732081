import { useState, useEffect } from "react";
import { createTicket, addUpdate } from "../../services/helpTicketService";

export default function HelpTicketsDetail({ profile, setView, view, ticket }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [solved, setSolved] = useState(false);
  const [status, setStatus] = useState("Open");
  const [updateContent, setUpdate] = useState("");

  useEffect(() => {
    if (ticket) {
      setTitle(ticket.title);
      setDescription(ticket.description);
      setSolved(ticket.solved);
      setType(ticket.type);
      setStatus(ticket.status);
    }
  }, [ticket]);

  function postUpdate() {
    if (updateContent !== "") {
      if (solved) {
        ticket.status = "Closed";
        ticket.solved = true;
      } else ticket.status = "Open";
      addUpdate(ticket, updateContent, profile).then(() => {
        setUpdate("");
        setView("list");
        setView("viewEdit");
      });
    }
  }

  function validate() {
    if (title !== "" && description !== "" && type !== "") {
      if (view === "new")
        createTicket("Open", title, description, type, solved, profile).then(
          () => setView("list")
        );
    }
  }

  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <h4>
            {view === "new" ? "New Ticket" : "Ticket #" + ticket.ticketId}
          </h4>
          <div>
            {view === "new" && (
              <button className="button-primary" onClick={() => validate()}>
                Create Ticket
              </button>
            )}
            <button
              className="button-secondary"
              onClick={() => setView("list")}
            >
              {view === "new" ? "Cancel" : "Back to List"}
            </button>
          </div>
        </div>
        <div className="panel-body">
          <table>
            <tbody>
              <tr>
                <th>
                  <div className="text">Organization</div>
                </th>
                <td>
                  <div className="text">{profile.orgCode}</div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Status</div>
                </th>
                <td>
                  <div className="text">{status}</div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Type</div>
                </th>
                <td>
                  <select
                    disabled={view !== "new"}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">-Select A Type-</option>
                    <option value="Product Suggestion">
                      Product Suggestion
                    </option>
                    <option value="Report Issue">Report Issue</option>
                    <option value="Request Assistance">
                      Request Assistance
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Title</div>
                </th>
                <td>
                  <input
                    className="input-wide"
                    disabled={view !== "new"}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength="50"
                    placeholder="Max 50 characters."
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Description</div>
                </th>
                <td>
                  <textarea
                    disabled={view !== "new"}
                    rows="5"
                    className="input-wide"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {view !== "new" && (
        <div className="panel">
          <div className="panel-header">
            <h4>Updates</h4>
          </div>
          <div className="panel-body">
            {ticket.updates && (
              <ul>
                {ticket.updates.map((u) => (
                  <li key={u.id}>{u}</li>
                ))}
              </ul>
            )}
            {!ticket.updates && (
              <ul>
                <li>
                  <div className="text">No updates.</div>
                </li>
              </ul>
            )}
            {ticket.status !== "Closed" && ticket.ownerId === profile.id && (
              <>
                <textarea
                  className="input-wide"
                  rows="3"
                  value={updateContent}
                  onChange={(e) => setUpdate(e.target.value)}
                />
                <br />
                <button className="button-primary" onClick={() => postUpdate()}>
                  Post Update
                </button>
                <input
                  type="checkbox"
                  value={solved}
                  onChange={(e) => setSolved(!solved)}
                />
                Mark Solved
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
