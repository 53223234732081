import { useEffect, useState } from "react";
import {
  getTerms,
  createTerm,
  saveTerm,
  deleteTerm,
  getUsers,
} from "../services/dataService";
import TermMenu from "./TermMenu";
import TermDetails from "./TermDetails";
import AdminMenu from "../components/AdminMenu";
import OrgDetails from "../components/OrgDetails";
import Messages from "../components/Messages";
import Users from "./Users";
import Access from "./Access";
import LLCConfiguration from "./LLC/LLCConfiguration";

export default function Admin({ profile }) {
  const [terms, setTerms] = useState();
  const [term, setTerm] = useState();
  const [adminModule, setAdminModule] = useState();
  const [users, setUsers] = useState();

  function termAdder(name, orgId, termDates, earliest, latest, active) {
    createTerm(
      name,
      orgId,
      profile.orgCode,
      termDates,
      earliest,
      latest,
      active,
      profile.admin
    ).then(setTerms);
  }

  function termSaver(
    id,
    name,
    orgId,
    processed,
    termDates,
    earliest,
    latest,
    active,
    processedEarlyArrivals
  ) {
    saveTerm(
      id,
      name,
      orgId,
      profile.orgCode,
      processed,
      termDates,
      earliest,
      latest,
      active,
      processedEarlyArrivals,
      profile.admin
    ).then(setTerms);
  }

  function termDeleter(term) {
    deleteTerm(term);
    setTerms(terms.filter((t) => t.id !== term.id));
    setTerm();
  }

  useEffect(() => {
    if (profile) {
      getTerms(profile.orgCode, profile.admin).then(setTerms);
      getUsers(profile.orgCode).then(setUsers);
    }
  }, [profile]);

  return (
    <div className="main-module">
      <AdminMenu setAdminModule={setAdminModule} />
      {adminModule === "terms" && (
        <div className="main-module">
          <TermMenu terms={terms} setTerm={setTerm} />
          {term && (
            <TermDetails
              term={term}
              setTerm={setTerm}
              adder={termAdder}
              saver={termSaver}
              deleter={termDeleter}
            />
          )}
        </div>
      )}
      {adminModule === "orgDetails" && (
        <OrgDetails orgCode={profile.orgCode} setAdminModule={setAdminModule} />
      )}
      {adminModule === "users" && <Users users={users} />}
      {adminModule === "messages" && <Messages profile={profile} />}
      {adminModule === "access" && <Access profile={profile} />}
      {adminModule === "llc" && <LLCConfiguration profile={profile} />}
    </div>
  );
}
