import { useState, useEffect } from "react";
import { getMessages } from "../services/dataService";

export default function MessageList({ profile, setMessage }) {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    getMessages(profile.orgCode, false).then((m) => setMessages(m.orgMessages));
  }, [profile]);

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>Messages</h4>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Start Date</div>
              </th>
              <th>
                <div className="text">End Date</div>
              </th>
              <th>
                <div className="text">Active</div>
              </th>
              <th>
                <div className="text">Content</div>
              </th>
              <th>
                <div className="text">View / Edit</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {messages &&
              messages.length > 0 &&
              messages.map((m) => (
                <tr key={m.id}>
                  <td>
                    <div className="text">{m.start}</div>
                  </td>
                  <td>
                    <div className="text">{m.end}</div>
                  </td>
                  <td>
                    <div className="text">{m.active ? "True" : "False"}</div>
                  </td>
                  <td>
                    <div className="text">{m.content.substring(0, 50)}...</div>
                  </td>
                  <td>
                    <div className="text">
                      <div
                        onClick={() => setMessage(m)}
                        className="button-link"
                      >
                        View
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            {!messages ||
              (messages.length === 0 && (
                <tr>
                  <td>
                    <div className="text">No Messages Found</div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
