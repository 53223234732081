import { db } from "../firebaseConfig";
import {
  collection,
  query,
  getDocs,
  addDoc,
  where,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";

export async function createLLC(orgCode, name, status, systemId, appId) {
  const data = {
    orgCode,
    name,
    status,
    systemId,
    appId,
  };
  const docRef = await addDoc(collection(db, "llc"), data);
  return docRef;
}

export async function getLLCs(orgCode) {
  let snapshot = await getDocs(
    query(
      collection(db, "llc"),
      where("orgCode", "==", orgCode),
      orderBy("name")
    )
  );
  if (snapshot.empty) return null;
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function getLLC(id) {
  const LLCref = doc(db, "llc", id);
  const LLCsnap = await getDoc(LLCref);
  if (LLCsnap.empty) return null;
  return { id: LLCsnap.id, ...LLCsnap.data() };
}
