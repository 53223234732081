import { useEffect, useState } from "react";
import GroupList from "./GroupList";
import ExistingGroup from "./ExistingGroup";
import {
  getGroups,
  deleteGroup,
  createGroup,
  saveGroup,
  getTerms,
} from "../services/dataService";
import NewGroup from "./NewGroup";

export default function EarlyArrivalGroups({ user, profile, termId }) {
  const [group, setGroup] = useState(null);
  const [myGroups, setGroups] = useState({});
  const [allGroups, setAllGroups] = useState(false);
  const [viewEdit, setViewEdit] = useState("view");
  const [terms, setTerms] = useState(null);
  const [viewAll, setAll] = useState(false);

  function adder(name, date, term) {
    createGroup(name, date, term, profile.orgCode, user.uid).then(() => {
      setGroup(null);
      getGroups(user.uid, profile.orgCode, "my", termId).then(setGroups);
      if (profile.admin) {
        getGroups(user.uid, profile.orgCode, "all", termId).then(setAllGroups);
      }
    });
  }

  function deleter(g) {
    deleteGroup(group.id);
    setGroups(myGroups.filter((g) => g.id !== group.id));
    setGroup(null);
    if (profile.admin) {
      getGroups(user.uid, profile.orgCode, "all", termId).then(setAllGroups);
    }
  }

  function updater(g) {
    saveGroup(g).then(() => {
      setViewEdit("view");
      getGroups(user.uid, profile.orgCode, "my", termId).then(setGroups);
      if (profile.admin) {
        getGroups(user.uid, profile.orgCode, "all", termId).then(setAllGroups);
      }
    });
  }

  useEffect(() => {
    if (user !== null) {
      getGroups(user.uid, profile.orgCode, "my", termId).then(setGroups);
      if (profile.admin) {
        getGroups(user.uid, profile.orgCode, "all", termId).then(setAllGroups);
      }
    }
  }, [user, profile, termId, group]);

  useEffect(() => {
    setViewEdit("view");
  }, [group]);

  useEffect(() => {
    if (profile) {
      getTerms(profile.orgCode, profile.admin).then(setTerms);
    }
  }, [profile]);

  return (
    <>
      {group === null && (
        <GroupList
          setAll={setAll}
          viewAll={viewAll}
          setGroup={setGroup}
          groups={viewAll ? allGroups : myGroups}
          profile={profile}
        />
      )}
      {group && group !== "new" && (
        <ExistingGroup
          group={group}
          deleter={deleter}
          updater={updater}
          viewEdit={viewEdit}
          setViewEdit={setViewEdit}
          terms={terms}
          setGroup={setGroup}
          profile={profile}
        />
      )}
      {group === "new" && (
        <NewGroup adder={adder} terms={terms} setGroup={setGroup} />
      )}
    </>
  );
}
