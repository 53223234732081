import { useState, useEffect } from "react";
import { getLLCs } from "../../services/LLCService";
import { getTerms } from "../../services/dataService";
import LLCApplications from "../LLC/LLCApplications";

export default function LLC({ profile }) {
  const [LLCs, setLLCs] = useState(null);
  const [LLC, setLLC] = useState("");
  const [terms, setTerms] = useState(null);
  const [term, setTerm] = useState("");

  useEffect(() => {
    getLLCs(profile.orgCode).then(setLLCs);
    getTerms(profile.orgCode, profile.admin).then(setTerms);
  }, [profile]);

  return (
    <div className="module-main">
      <div className="module-header">
        <h3>Living-Learning Communities</h3>
        <select onChange={(e) => setLLC(e.target.value)}>
          <option value="">-Select An LLC-</option>
          {LLCs &&
            LLCs.map((l) => (
              <option key={l.id} value={l.id}>
                {l.name}
              </option>
            ))}
        </select>
        <select onChange={(e) => setTerm(e.target.value)}>
          <option value="">-Select A Term-</option>
          {terms &&
            terms.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
        </select>
      </div>
      {LLC !== "" && term !== "" && <LLCApplications LLC={LLC} term={term} />}
    </div>
  );
}
