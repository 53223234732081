import { useState, useEffect } from "react";
import { getTickets } from "../../services/helpTicketService";

export default function HelpTicketsList({ profile, setView, view, setTicket }) {
  const [tickets, setTickets] = useState({});
  const [displayTickets, setDisplay] = useState({});
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getTickets(profile).then((t) => {
      setTickets(t);
      setDisplay(t);
    });
  }, [profile, view]);

  useEffect(() => {
    if (filter !== "") {
      setDisplay(tickets.filter((t) => t.status === filter));
    } else setDisplay(tickets);
  }, [filter, tickets]);

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>Support Tickets</h4>
        <div>
          <select
            disabled={!profile.admin}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="Open">Open</option>
            <option value="Closed">Closed</option>
          </select>
          <button
            disabled={!profile.admin}
            className="button-primary"
            onClick={() => setView("new")}
          >
            New Ticket
          </button>
        </div>
      </div>
      {profile.admin ? (
        <>
          <div className="panel-body">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="text">Ticket</div>
                  </th>
                  <th>
                    <div className="text">Title</div>
                  </th>
                  <th>
                    <div className="text">Created Date</div>
                  </th>
                  <th>
                    <div className="text">Status</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayTickets.length > 0 &&
                  displayTickets.map((t) => (
                    <tr key={t.id}>
                      <td>
                        <div
                          className="button-link"
                          onClick={() => {
                            setTicket(t);
                            setView("editView");
                          }}
                        >
                          {t.ticketId}
                        </div>
                      </td>
                      <td>
                        <div className="text">{t.title}</div>
                      </td>
                      <td>
                        <div className="text">{t.dateCreated}</div>
                      </td>
                      <td>
                        <div className="text">{t.status}</div>
                      </td>
                    </tr>
                  ))}
                {displayTickets.length === 0 && (
                  <tr>
                    <td>
                      <div className="text">
                        No Tickets Found for {profile.orgCode}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="panel-footer">
            <ul>
              <li>
                <div className="text">
                  All admin users can see all tickets within their organization
                  but only the Owner/Creater can post updates to it.
                </div>
              </li>
              <li>
                <div className="text">
                  If a ticket needs to be reassigned to a new Owner, an admin
                  must open a ticket and make a request with the ticket number.
                </div>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <div className="panel-body">
          <div className="text">
            We're sorry, only system administrators are able to open and manage
            support tickets for your organization. Please contact your
            organization's system admininistrator for assistance.
          </div>
        </div>
      )}
    </div>
  );
}
