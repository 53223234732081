import { useState } from "react";
import LateStayActionMenu from "./LateStayActionMenu";
import LateStayGroupDetails from "./LateStayGroupDetails";
import LateStayGroupMembers from "./LateStayGroupMembers";

export default function LateStayGroupExisting({
  group,
  deleter,
  updater,
  viewEdit,
  setViewEdit,
  terms,
  setGroup,
  profile,
}) {
  const [message, setMessage] = useState("Group Information");

  function validate() {
    if (group.name !== "") {
      if (group.term !== "") {
        if (group.date !== "") {
          if (group.term.termDates) {
            if (group.date >= group.term.earliest) {
              if (group.date <= group.term.latest) {
                updater(group);
              } else {
                /*setMessage(
                  "Date must be less than or equal to " +
                    group.term.latest +
                    "!"
                );*/
                updater(group);
              }
            } else {
              setMessage(
                "Date must be greater than or equal to " +
                  group.term.earliest +
                  "!"
              );
            }
          } else {
            updater(group);
          }
        } else {
          setMessage("Date cannot be blank!");
        }
      } else {
        setMessage("Term cannot be blank!");
      }
    } else {
      setMessage("Name cannot be blank!");
    }
  }

  return (
    <div className="earlyArrival-group">
      <div className="panel-header">
        <h4>{group.name}</h4>
        <LateStayActionMenu
          group={group}
          profile={profile}
          viewEdit={viewEdit}
          setViewEdit={setViewEdit}
          deleter={deleter}
          validate={validate}
          setGroup={setGroup}
        />
      </div>
      <LateStayGroupDetails
        group={group}
        viewEdit={viewEdit}
        terms={terms}
        profile={profile}
        message={message}
        setMessage={setMessage}
      />
      {viewEdit === "view" && <LateStayGroupMembers group={group} />}
    </div>
  );
}
