import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYWaYSzWeffGIIWLIooLT1aZVV4id91U8",
  authDomain: "partner-portal-4397f.firebaseapp.com",
  projectId: "partner-portal-4397f",
  storageBucket: "partner-portal-4397f.appspot.com",
  messagingSenderId: "113787554428",
  appId: "1:113787554428:web:6e3cc32bd97b6ef7fe17f9",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
