import { useState, useEffect } from "react";
import HelpTickets from "./HelpTickets";
import HelpResources from "./HelpResources";
import HelpWhatsNew from "./HelpWhatsNew";
import HelpAbout from "./HelpAbout";

export default function Help({ profile }) {
  const [tab, setTab] = useState("whatsNew");
  useEffect(() => {}, [profile]);

  return (
    <div className="module-main">
      <div className="module-header">
        <h3>Help & Resources</h3>
        <div>
          <button className="button-primary" onClick={() => setTab("whatsNew")}>
            What's New
          </button>
          <button className="button-primary" onClick={() => setTab("about")}>
            About
          </button>
          <button
            className="button-primary"
            onClick={() => setTab("resources")}
          >
            Resources
          </button>
          <button className="button-primary" onClick={() => setTab("tickets")}>
            Tickets
          </button>
        </div>
      </div>
      {tab === "tickets" && <HelpTickets profile={profile} />}
      {tab === "resources" && <HelpResources profile={profile} />}
      {tab === "whatsNew" && <HelpWhatsNew />}
      {tab === "about" && <HelpAbout />}
    </div>
  );
}
