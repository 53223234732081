import { globalData } from "../services/dataService";

export default function UserDetailsView({ user, setUser }) {
  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <h4>User Details</h4>
        </div>
        <div className="panel-body">
          <table>
            <tbody>
              <tr>
                <th>
                  <div className="text">Name:</div>
                </th>
                <td>
                  <div className="text">{user.name}</div>
                </td>
                <th>
                  <div className="text">Email:</div>
                </th>
                <td>
                  <div className="text">{user.email}</div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Active:</div>
                </th>
                <td>
                  <div className="text">{user.active ? "True" : "False"}</div>
                </td>
                <th>
                  <div className="text">Admin:</div>
                </th>
                <td>
                  <div className="text">{user.admin ? "True" : "False"}</div>
                </td>
              </tr>
              <tr>
                <th>
                  <div className="text">Verified:</div>
                </th>
                <td>
                  <div className="text">{user.verified ? "True" : "False"}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="panel">
        <div className="panel-header">
          <h4>User Permissions</h4>
        </div>
        <div className="panel-body">
          <table>
            <tbody>
              {globalData.access.get("earlyArrival") && (
                <tr>
                  <th>
                    <div className="text">Early Arrival:</div>
                  </th>
                  <td>
                    <div className="text">True</div>
                  </td>
                </tr>
              )}
              {globalData.access.get("llc") && (
                <tr>
                  <th>
                    <div className="text">LLC:</div>
                  </th>
                  <td>
                    <div className="text">True</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
