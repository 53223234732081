import MessageActions from "./MessageActions";
import MessageDetail from "./MessageDetail";
import MessageNew from "./MessageNew";
import MessageList from "./MessagesList";
import { useState } from "react";

export default function Messages({ profile }) {
  const [message, setMessage] = useState(null);
  const [viewEdit, setViewEdit] = useState("view");
  return (
    <div>
      <div className="module-header">
        <h3>Messages</h3>
        <MessageActions
          setMessage={setMessage}
          setViewEdit={setViewEdit}
          message={message}
          viewEdit={viewEdit}
        />
      </div>
      {message === null && (
        <MessageList profile={profile} setMessage={setMessage} />
      )}
      {message !== null && message !== "new" && (
        <MessageDetail message={message} />
      )}
      {message === "new" && (
        <MessageNew profile={profile} setMessage={setMessage} />
      )}
    </div>
  );
}
