import { useState, useEffect } from "react";
import { globalData } from "../services/dataService";

export default function GroupList({
  setAll,
  viewAll,
  setGroup,
  groups,
  profile,
}) {
  const [search, setSearch] = useState("");
  const [groupList, setGroupList] = useState(groups);

  useEffect(() => {
    if (search !== "") {
      setGroupList(
        groups.filter((g) =>
          g.name.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setGroupList(groups);
    }
  }, [search, groups]);

  return (
    <div className="earlyArrival-list">
      <div className="panel-header">
        <h4>Early Arrival Groups</h4>
        <div>
          <input
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {globalData.access.get("earlyArrival") !== "Read Only" && (
            <button className="button-primary" onClick={() => setGroup("new")}>
              New Group
            </button>
          )}
          {profile.admin && (
            <button onClick={() => setAll(!viewAll)} className="button-primary">
              {viewAll ? "View Mine" : "View All"}
            </button>
          )}
        </div>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Group Name</div>
              </th>
              <th>
                <div className="text">Term</div>
              </th>
              <th>
                <div className="text">Date</div>
              </th>
              <th>
                <div className="text">Status</div>
              </th>
              <th>
                <div className="text">Owner</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {groups.length === 0 && (
              <tr>
                <td>
                  <div className="text">No Groups Found</div>
                </td>
              </tr>
            )}
            {groupList.length > 0 &&
              groupList.map((g) => (
                <tr key={g.id}>
                  <td>
                    <button className="button-link" onClick={() => setGroup(g)}>
                      {g.name}
                    </button>
                  </td>
                  <td>
                    <div className="text">{g.term.name}</div>
                  </td>
                  <td>
                    <div className="text">{g.date}</div>
                  </td>
                  <td>
                    <div className="text">{g.status}</div>
                  </td>
                  <td>
                    <div className="text">
                      {globalData.profiles.get(g.ownerId).name}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
