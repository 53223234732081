export default function UserMenu({ users, setUser }) {
  return (
    <div className="side-menu">
      <h2 className="side-menu-header">Users</h2>
      {users.length
        ? users.map((u) => (
            <button
              className="side-menu-item"
              key={u.uid}
              onClick={() => setUser(u)}
            >
              {u.name}
            </button>
          ))
        : "No Users"}
    </div>
  );
}
