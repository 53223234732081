export default function TermMenu({ terms, setTerm }) {
  return (
    <div className="side-menu">
      <h2 className="side-menu-header">Terms</h2>
      {terms.length ? (
        terms.map((term) => (
          <button
            className="side-menu-item"
            key={term.id}
            onClick={() => setTerm(term)}
          >
            {term.name}
          </button>
        ))
      ) : (
        <div className="side-menu-item">"No Terms"</div>
      )}
      <div className="side-menu-item" onClick={() => setTerm("new")}>
        <i>New Term</i>
      </div>
    </div>
  );
}
