import { useEffect, useState } from "react";
import {
  getGroupMembers,
  deleteGroupMember,
  addToGroup,
  globalData,
} from "../services/dataService";

export default function GroupMembers({ group }) {
  const [groupMembers, setGroupMembers] = useState();
  const [message, setMessage] = useState("");
  const [id, setId] = useState("");

  function submit(e) {
    e.preventDefault();
    if (id.indexOf(" ") === -1) {
      if (id === "") {
        setMessage("ID Cannot Be Blank");
      } else {
        addToGroup(id, group).then(() =>
          getGroupMembers(group).then(setGroupMembers)
        );
        group.groupSize = group.groupSize + 1;
        setId("");
        setMessage("");
      }
    } else {
      setMessage("ID Cannot Contain Spaces");
    }
  }

  useEffect(() => {
    getGroupMembers(group).then(setGroupMembers);
  }, [group]);

  function deleter(memberId, groupId) {
    deleteGroupMember(memberId, groupId);
    setGroupMembers(groupMembers.filter((m) => m.id !== memberId));
  }

  return (
    <div className="panel-body">
      <div className="module-header">
        {globalData.access.get("earlyArrival") !== "Read Only" && (
          <form onSubmit={submit}>
            <div className="row">
              <input
                value={id}
                onChange={(e) => setId(e.target.value)}
                placeholder="Add Student by ID"
              />
              <div className="text-error">{message}</div>
            </div>
          </form>
        )}
        <div>
          {groupMembers && (
            <div className="text">
              <b>Count: </b>
              {groupMembers.length}
            </div>
          )}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <div className="text">ID</div>
            </th>
            <th>
              <div className="text">First</div>
            </th>
            <th>
              <div className="text">Last</div>
            </th>
            <th>
              <div className="text">
                Earliest Date
                <span title="Shows whether the member is being requested earlier by another group.">
                  {" (?) "}
                </span>
              </div>
            </th>
            <th>
              <div className="text">Action</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {groupMembers ? (
            groupMembers.map((member) => (
              <tr key={member.id}>
                <td>
                  <div className="text">{member.orgId}</div>
                </td>
                <td>
                  <div className="text">{member.first}</div>
                </td>
                <td>
                  <div className="text">{member.last}</div>
                </td>
                <td>
                  <div className="text">{member.earliestDate}</div>
                </td>
                <td>
                  {globalData.access.get("earlyArrival") !== "Read Only" && (
                    <button
                      onClick={() => {
                        deleter(member.id, group.id);
                        group.groupSize = group.groupSize - 1;
                      }}
                      className="button-link"
                    >
                      Remove
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr className="group-members-item">
              <td>
                <div className="text">No Members</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
