export default function Dashboard({ profile, messages }) {
  return (
    <div className="main-dashboard">
      <div className="dashboard">
        <h4>Welcome, {profile.name}!</h4>
        {!profile.active && (
          <div className="popup">
            <h3>
              Your account is not active. <br />
              Please contact your administrator. <br />
              You will not have access to features <br />
              of this portal unless active.
            </h3>
          </div>
        )}
        {!profile.verified && (
          <div>
            <div className="popup">
              <h3>
                Your account has not yet been verified.
                <br />
                Please check your email for a verification link.
                <br />
                You will not have access to features of this portal until
                verified.
              </h3>
              <h4>
                If you recently verified your email,
                <br /> please logout and login. <br />
                You may also need to refresh <br />
                your browser upon login.
              </h4>
            </div>
          </div>
        )}
        {messages.systemMessages && messages.systemMessages.length > 0 && (
          <div className="panel">
            <div className="panel-header">
              <h5>System Messages</h5>
            </div>
            <div className="panel-body">
              <div className="text">
                <ul>
                  {messages.systemMessages.map((message) => (
                    <li key={message.id}>{message.content}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="panel">
          <div className="panel-header">
            <h5>Organization Messages</h5>
          </div>
          <div className="panel-body">
            <div className="text">
              <ul>
                {messages.orgMessages && messages.orgMessages.length > 0 ? (
                  messages.orgMessages.map((message) => (
                    <li key={message.id}>{message.content}</li>
                  ))
                ) : (
                  <li>
                    <i>There are no organization messages at this time.</i>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
