import { db } from "../firebaseConfig";
import sendEmail from "./outboxService";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";

export async function getTickets(profile) {
  const snapshot = await getDocs(
    query(
      collection(db, "helpTickets"),
      where("orgCode", "==", profile.orgCode),
      orderBy("dateCreated")
    )
  );
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function createTicket(
  status,
  title,
  description,
  type,
  solved,
  profile
) {
  const currentDate = new Date(Date.now());
  const createdDate =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1) +
    "-" +
    currentDate.getDate();
  const createdTime = currentDate.getHours() + ":" + currentDate.getMinutes();
  const ticketId = (createdDate + createdTime)
    .replaceAll("-", "")
    .replaceAll(":", "");
  const data = {
    status: status,
    title: title,
    description: description,
    type: type,
    solved: solved,
    ownerId: profile.id,
    orgCode: profile.orgCode,
    ticketId: ticketId,
    dateCreated: createdDate,
    timeCreated: createdTime,
    updates: ["Ticket Created on " + createdDate + " at " + createdTime + "."],
  };
  const docRef = await addDoc(collection(db, "helpTickets"), data);
  await updateDoc(docRef, "id", docRef.id);
  await updateDoc(docRef, "helpTicketId", docRef.id);
  await sendEmail(
    ["brandon@brandonholmes.net"],
    "New Ticket Submitted: " + ticketId,
    "A new update has been posted to Ticket " + ticketId + "."
  );
}

export async function addUpdate(ticket, update, profile) {
  let updatesArray = ticket.updates;
  updatesArray.push("Update from " + profile.name + ": " + update);
  const docRef = doc(db, "helpTickets", ticket.id);
  const data = {
    status: ticket.status,
    solved: ticket.solved,
  };
  await updateDoc(docRef, data);
  await updateDoc(docRef, "updates", updatesArray);
  await sendEmail(
    ["brandon@brandonholmes.net"],
    "Update Posted to Ticket: " + ticket.ticketId,
    "A new update has been posted to Ticket " + ticket.ticketId + "."
  );
}
