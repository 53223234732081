import { useState, useEffect } from "react";
import { getMembers } from "../services/dataService";

export default function EarlyArrivalMembers({ user, profile, termId }) {
  const [search, setSearch] = useState("");
  const [members, setMembers] = useState(null);
  const [membersList, setMembersList] = useState(null);

  useEffect(() => {
    getMembers(profile.orgCode, termId).then(setMembers);
  }, [profile, termId]);

  useEffect(() => {
    if (search !== "") {
      setMembersList(
        members.filter(
          (member) =>
            member.first
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            member.last
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            member.orgId.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setMembersList(members);
    }
  }, [search, members]);

  return (
    <div className="earlyArrival-list">
      <div className="panel-header">
        <h4>Early Arrival Members ({membersList ? membersList.length : 0})</h4>
        <input
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">ID</div>
              </th>
              <th>
                <div className="text">First</div>
              </th>
              <th>
                <div className="text">Last</div>
              </th>
              <th>
                <div className="text">Date</div>
              </th>
              <th>
                <div className="text">Group</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {membersList !== null &&
              membersList.length > 0 &&
              membersList.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className="text">{member.orgId}</div>
                  </td>
                  <td>
                    <div className="text">{member.first}</div>
                  </td>
                  <td>
                    <div className="text">{member.last}</div>
                  </td>
                  <td>
                    <div className="text">{member.earliestDate}</div>
                  </td>
                  <td>
                    <div className="text">{member.earliestGroup}</div>
                  </td>
                </tr>
              ))}
            {membersList === null ||
              (membersList.length === 0 && (
                <tr>
                  <td>
                    <div className="text">No Members</div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
