import { useState, useEffect } from "react";
import { saveGroup } from "../services/dataService";
import { saveLateStayGroup } from "../services/lateStayService";
import { getUsers } from "../services/dataService";

export default function GroupOwnerEdit({
  group,
  profile,
  setChangeOwner,
  groupType,
}) {
  const [ownerId, setOwnerId] = useState(group.ownerId);
  const [users, setUsers] = useState();

  function save() {
    group.ownerId = ownerId;
    if (groupType === "LateStay") {
      saveLateStayGroup(group);
    } else if (groupType === "EarlyArrival") {
      saveGroup(group);
    }
    setChangeOwner(false);
  }

  useEffect(() => {
    if (profile) {
      getUsers(profile.orgCode, true).then(setUsers);
    }
  }, [profile]);

  return (
    <div className="popup-wrapper">
      <div className="popup">
        <div className="panel">
          <div className="panel-body">
            <h4>Change User</h4>
            <select onChange={(e) => setOwnerId(e.target.value)}>
              <option>--Select New Owner--</option>
              {users &&
                users.map((u) => (
                  <option key={u.id} value={u.uid}>
                    {u.name}
                  </option>
                ))}
            </select>
            <div className="button-primary" onClick={() => save()}>
              Save
            </div>
            <div
              className="button-primary"
              onClick={() => setChangeOwner(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
