import { useState, useEffect } from "react";
import { getTerms } from "../../services/dataService";
import LateStayGroups from "./LateStayGroups";
import LateStayMembers from "./LateStayMembers";

export default function LateStay({ user, profile }) {
  const [tab, setTab] = useState("groups");
  const [termId, setTermId] = useState("");
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    getTerms(profile.orgCode, profile.admin).then(setTerms);
  }, [profile]);

  return (
    <div className="module-main">
      <div className="module-header">
        <h3>Late Stay</h3>
        <select onChange={(e) => setTermId(e.target.value)}>
          <option value="">-Select A Term-</option>
          {terms &&
            terms.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
        </select>
        <div>
          <button className="button-primary" onClick={() => setTab("groups")}>
            Groups
          </button>
          {profile && profile.admin && (
            <button
              className="button-primary"
              onClick={() => setTab("members")}
            >
              Members
            </button>
          )}
        </div>
      </div>
      {tab === "groups" && (
        <LateStayGroups user={user} profile={profile} termId={termId} />
      )}
      {tab === "members" && (
        <LateStayMembers user={user} profile={profile} termId={termId} />
      )}
    </div>
  );
}
