import { useEffect, useState } from "react";
import LateStayGroupList from "./LateStayGroupList";
import LateStayGroupExisting from "./LateStayGroupExisting";
import {
  getLateStayGroups,
  deleteLateStayGroup,
  createLateStayGroup,
  saveLateStayGroup,
} from "../../services/lateStayService";
import { getTerms } from "../../services/dataService";
import LateStayGroupNew from "./LateStayGroupNew";

export default function LateStayGroups({ user, profile, termId }) {
  const [group, setGroup] = useState(null);
  const [myGroups, setGroups] = useState({});
  const [allGroups, setAllGroups] = useState(false);
  const [viewEdit, setViewEdit] = useState("view");
  const [terms, setTerms] = useState(null);
  const [viewAll, setAll] = useState(false);

  function adder(name, date, term) {
    createLateStayGroup(name, date, term, profile.orgCode, user.uid).then(
      () => {
        setGroup(null);
        getLateStayGroups(user.uid, profile.orgCode, "my", termId).then(
          setGroups
        );
        if (profile.admin) {
          getLateStayGroups(user.uid, profile.orgCode, "all", termId).then(
            setAllGroups
          );
        }
      }
    );
  }

  function deleter(g) {
    deleteLateStayGroup(group.id);
    setGroups(myGroups.filter((g) => g.id !== group.id));
    setGroup(null);
    if (profile.admin) {
      getLateStayGroups(user.uid, profile.orgCode, "all", termId).then(
        setAllGroups
      );
    }
  }

  function updater(g) {
    saveLateStayGroup(g).then(() => {
      setViewEdit("view");
      getLateStayGroups(user.uid, profile.orgCode, "my", termId).then(
        setGroups
      );
      if (profile.admin) {
        getLateStayGroups(user.uid, profile.orgCode, "all", termId).then(
          setAllGroups
        );
      }
    });
  }

  useEffect(() => {
    if (user !== null) {
      getLateStayGroups(user.uid, profile.orgCode, "my", termId).then(
        setGroups
      );
      if (profile.admin) {
        getLateStayGroups(user.uid, profile.orgCode, "all", termId).then(
          setAllGroups
        );
      }
    }
  }, [user, profile, termId, group]);

  useEffect(() => {
    setViewEdit("view");
  }, [group]);

  useEffect(() => {
    if (profile) {
      getTerms(profile.orgCode, profile.admin).then(setTerms);
    }
  }, [profile]);

  return (
    <>
      {group === null && (
        <LateStayGroupList
          setAll={setAll}
          viewAll={viewAll}
          setGroup={setGroup}
          groups={viewAll ? allGroups : myGroups}
          profile={profile}
          termId={termId}
        />
      )}
      {group && group !== "new" && (
        <LateStayGroupExisting
          group={group}
          deleter={deleter}
          updater={updater}
          viewEdit={viewEdit}
          setViewEdit={setViewEdit}
          terms={terms}
          setGroup={setGroup}
          profile={profile}
        />
      )}
      {group === "new" && (
        <LateStayGroupNew adder={adder} terms={terms} setGroup={setGroup} />
      )}
    </>
  );
}
