export default function AccessList({ profile, accessKeys, setKey, key }) {
  return (
    <div className="panel">
      <div className="panel-header">
        <h4>Access Keys</h4>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Key</div>
              </th>
              <th>
                <div className="text">Status</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {accessKeys !== null &&
              accessKeys.map((m) => (
                <tr key={m.id}>
                  <td>
                    <div className="text">{m.description}</div>
                  </td>
                  <td>
                    <button onClick={() => setKey(m)} className="button-link">
                      {m.status}
                    </button>
                  </td>
                </tr>
              ))}
            {accessKeys === null && (
              <tr>
                <td>
                  <div className="text">No Keys Found</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
