import { useEffect, useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
//const processEarlyArrivals = httpsCallable(functions, "processEarlyArrivals");
const newProcessearlyArrivals = httpsCallable(
  functions,
  "newProcessEarlyArrivals"
);

export default function TermDetails({ term, setTerm, adder, saver, deleter }) {
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [orgId, setOrgId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [processEarlyArrival, setProcessEA] = useState(false);
  const [earliest, setEarliest] = useState("");
  const [latest, setLatest] = useState("");
  const [termDates, setTermDates] = useState(false);
  const [active, setActive] = useState(false);

  function processEA() {
    newProcessearlyArrivals({
      term: term,
      orgCode: term.orgCode,
    });
    term.processedEarlyArrivals = true;
    save();
    setTerm(null);
    setProcessEA(false);
  }

  useEffect(() => {
    if (term && term !== "new") {
      setName(term.name);
      setOrgId(term.orgId);
      setProcessEA(false);
      setEarliest(term.earliest);
      setLatest(term.latest);
      setTermDates(term.termDates);
      setActive(term.active);
    } else if (term === "new") {
      setName("");
      setStatus("Active");
      setOrgId("");
      setProcessEA(false);
      setEarliest("");
      setLatest("");
      setTermDates(false);
      setActive(false);
    }
    setConfirm(false);
  }, [term]);

  function save() {
    saver(
      term.id,
      name,
      orgId,
      term.processed,
      termDates,
      earliest,
      latest,
      active,
      term.processedEarlyArrivals
    );
    setName();
    setOrgId();
    setTerm(null);
  }

  function add() {
    adder(name, orgId, termDates, earliest, latest, active);
    setName();
    setOrgId();
    setTerm(null);
  }

  return (
    <div className="module-main">
      <div className="action-menu">
        {term && term !== "new" && (
          <button className="button-primary" onClick={() => save()}>
            Save
          </button>
        )}
        {term === "new" && (
          <button className="button-primary" onClick={() => add()}>
            Create
          </button>
        )}
        {term && (
          <button className="button-primary" onClick={() => setTerm(null)}>
            Close
          </button>
        )}
        {term && term !== "new" && (
          <button className="button-primary" onClick={() => setConfirm(true)}>
            Delete
          </button>
        )}
      </div>
      {confirm && (
        <div className="action-menu">
          <button className="button-primary" onClick={() => deleter(term)}>
            Delete "{term.name}"?
          </button>
          <button className="button-primary" onClick={() => setConfirm(false)}>
            Cancel
          </button>
        </div>
      )}
      <div className="panel">
        <div className="panel-header">
          <h4>Term Information</h4>
        </div>
        <div className="panel-body">
          <div className="panel-primary-item">
            <div className="label">Name:</div>
            <input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="panel-primary-item">
            <div className="label">Status:</div>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="Active">Active</option>
              <option value="Inactive">Disabled</option>
              <option value="Hidden">Hidden</option>
            </select>
          </div>
          <div className="panel-primary-item">
            <div className="label">TermID:</div>
            <input value={orgId} onChange={(e) => setOrgId(e.target.value)} />
          </div>
          <div className="panel-primary-item">
            <div className="label">Term Active:</div>
            <input
              type="checkbox"
              className="input"
              checked={active}
              onChange={(e) => setActive(!active)}
            />
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="panel-header">
          <h4>Term Dates</h4>
        </div>
        <div className="panel-body">
          <div className="panel-primary-item">
            <div className="label">Enforce Term Dates</div>
            <input
              type="checkbox"
              className="input"
              checked={termDates}
              onChange={(e) => setTermDates(!termDates)}
            />
          </div>
          <div className="panel-primary-item">
            <div className="label">Earliest</div>
            <input
              type="date"
              value={earliest}
              disabled={!termDates}
              onChange={(e) => setEarliest(e.target.value)}
            />
          </div>
          <div className="panel-primary-item">
            <div className="label">Latest</div>
            <input
              type="date"
              value={latest}
              disabled={!termDates}
              onChange={(e) => setLatest(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="panel">
        {term !== "new" && (
          <div className="wrapper">
            <button
              className="button-primary"
              onClick={() => setProcessEA(true)}
              disabled={term.processedEarlyArrivals}
            >
              Process Early Arrivals
            </button>
            {processEarlyArrival && (
              <div className="wrapper">
                <div className="label">
                  Please confirm you wish to process early arrivals for this
                  Term.
                </div>
                <button onClick={() => processEA()} className="button-primary">
                  Confirm & Process
                </button>
                <button
                  onClick={() => setProcessEA(false)}
                  className="button-secondary"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
