import { useState } from "react";
import UserMenu from "./UserMenu";
import UserDetails from "./UserDetails";

export default function Users({ users }) {
  const [selectedUser, setUser] = useState();

  return (
    <div className="main-module">
      <UserMenu users={users} setUser={setUser} />
      {selectedUser && <UserDetails user={selectedUser} setUser={setUser} />}
    </div>
  );
}
