import { useState } from "react";

export default function LLCAssignments({ llc }) {
  const [newRow, setNewRow] = useState(false);

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>{llc.name}</h4>
        <button className="button-primary" onClick={() => setNewRow(true)}>
          New User
        </button>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Users</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {newRow && (
              <tr>
                <td>
                  <select>
                    <option value="">-Select User-</option>
                    <option value={"test"}>test</option>
                  </select>
                </td>
                <td>
                  <button
                    className="button-primary"
                    onClick={() => setNewRow(false)}
                  >
                    Add New
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
