export default function HelpResources({ profile }) {
  return (
    <div className="module-main">
      <div className="panel-header">
        <h4>Resources</h4>
      </div>
      <div className="panel-body">
        <table>
          <thead>
            <tr>
              <th>
                <div className="text">Title</div>
              </th>
              <th>
                <div className="text">Last Updated</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="text">No Resources Found</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="panel-footer">
        <div className="text">Resources Are Coming Soon!</div>
      </div>
    </div>
  );
}
