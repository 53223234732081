import { useState } from "react";
import { updateUser } from "../services/dataService";

export default function UserDetailsEdit({ user, setViewEdit }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [active, setActive] = useState(user.active);
  const [verified, setVerified] = useState(user.verified);
  const [admin, setAdmin] = useState(user.admin);

  function save() {
    if (name !== "" && email !== "") {
      user.name = name;
      user.email = email;
      user.admin = admin;
      user.active = active;
      user.verified = verified;
      updateUser(user).then(setViewEdit("view"));
    }
  }

  return (
    <div className="panel">
      <div className="panel-header">
        <h4>{name}</h4>
      </div>
      <div className="panel-body">
        <table>
          <tbody>
            <tr>
              <th>
                <div className="text">Name:</div>
              </th>
              <td>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </td>
              <th>
                <div className="text">Email:</div>
              </th>
              <td>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div className="text">Active:</div>
              </th>
              <td>
                <input
                  type="checkbox"
                  checked={active}
                  value={active}
                  onChange={() => setActive(!active)}
                />
              </td>
              <th>
                <div className="text">Admin:</div>
              </th>
              <td>
                <input
                  type="checkbox"
                  checked={admin}
                  value={admin}
                  onChange={() => setAdmin(!admin)}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div className="text">Verified:</div>
              </th>
              <td>
                <input
                  type="checkbox"
                  checked={verified}
                  value={verified}
                  onChange={() => setVerified(!verified)}
                />
              </td>
              <td>
                <button className="button-primary" onClick={() => save()}>
                  Save
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
