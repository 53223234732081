import { useEffect, useState } from "react";
import HelpTicketsList from "./HelpTicketsList";
import HelpTicketsDetail from "./HelpTicketsDetail";

export default function HelpTickets({ profile }) {
  const [view, setView] = useState("list");
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    if (view === "list") setTicket(null);
  }, [view]);

  return (
    <>
      {view === "list" && (
        <HelpTicketsList
          profile={profile}
          setView={setView}
          view={view}
          setTicket={setTicket}
        />
      )}
      {view !== "list" && (
        <HelpTicketsDetail
          profile={profile}
          setView={setView}
          view={view}
          ticket={ticket}
        />
      )}
    </>
  );
}
